import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBnkPpWrLWD78Za76fJqDyebP3Y4yTyagc",
  authDomain: "boasoft.firebaseapp.com",
  projectId: "boasoft",
  storageBucket: "boasoft.appspot.com",
  messagingSenderId: "160055909984",
  appId: "1:160055909984:web:31991aa8490f128fbeed05"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };